import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Rx from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  url: any = 'https://www.saphelpline.com/';
  domainAndScreenPermissions = new Rx.BehaviorSubject({});
  userDetails = new Rx.BehaviorSubject({});
  orgOnboarded = new Rx.BehaviorSubject({});
  updatePermissions = new Rx.BehaviorSubject(false);
  userGroup: any = {};
  // apiUrl: any = 'http://localhost:8182/';

  // apiUrl: any = 'https://api.dev.idmx.io/'
  apiUrl: any = 'https://api.demo.idmx.io/'
  // apiUrl: any = 'https://api.prod.idmx.io/'
  // apiUrl:any = "https://e127-122-161-49-213.ngrok-free.app/"

  geturl: string;
  posturl: string;
  userType: string;
  organisationId: string;
  access: any = {};
  updatePassword: any;
  userEmail: any;
  organisationName: any;
  userName: any;
  userId: any;
  domain: any;
  phoneNo: any;
// oragsanisationId
  constructor(public http: HttpClient) { }

  massUpload(payload: any, data: any) {
    return this.http.post(this.apiUrl + payload, data)
  }

  fetchVariants(object) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + object, { userId: "61de8d622d5a3a48e34a8bec" }).subscribe(resp => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getUserDetails(userId) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'getUserDetails', { userId: userId }).subscribe((resp: any) => {
        console.log("getUserDetails ", resp)
        if (resp) {
          if (resp.organisationType == 'organisation'){
            this.userType = 'organisation';
            this.organisationId = resp._id;
            this.access = resp.accessRights
            this.userEmail = resp.primaryEmail
            this.userId = resp._id
            this.organisationName = resp.organisationName
            localStorage.setItem('userName',resp.userName)
          }
          else
          {
            this.userType = 'user';
            this.organisationId = resp.organisationId;
            this.access = resp.domainAndScreenPermissions
            this.userEmail = resp.email
            this.userName = resp.username
            this.userId = resp._id
            this.phoneNo = resp.num
            localStorage.setItem('userName',resp.username)
          }
                  console.log("this.organisationId ", this.organisationId)
          this.domainAndScreenPermissions.next(resp.domainAndScreenPermissions);
          this.userDetails.next(resp);
        }
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getPriceDetails(userId) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'fetchPricePayment', { userId: userId }).subscribe((resp: any) => {
        console.log("getPriceDetails ", resp)
        if (resp) {
          if (resp.organisationType == 'organisation'){
            this.userType = 'organisation';
            this.organisationId = resp._id;
            this.access = resp.accessRights
            this.userEmail = resp.primaryEmail
            this.organisationName = resp.organisationName
            this.domain = resp.domain
          }
          else
          {
            this.userType = 'user';
            this.organisationId = resp.organisationId;
            this.access = resp.domainAndScreenPermissions
            this.userEmail = resp.email
            this.userName = resp.username
            this.userId = resp._id
          }
                  console.log("this.organisationId ", this.organisationId)
          this.domainAndScreenPermissions.next(resp.domainAndScreenPermissions);
          this.userDetails.next(resp);
        }
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  getPaymentDetails(userId) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'fetchPricePayment', { userId: userId }).subscribe((resp: any) => {
          console.log("getPaymentDetails ", resp)
        if (resp) {
          if (resp.organisationType == 'organisation'){
            this.userType = 'organisation';
            this.organisationId = resp._id;
            this.access = resp.accessRights
            this.userEmail = resp.primaryEmail
            this.organisationName = resp.organisationName
            this.domain = resp.domain
          }
          else
          {
            this.userType = 'user';
            this.organisationId = resp.organisationId;
            this.access = resp.domainAndScreenPermissions
            this.userEmail = resp.email
            this.userName = resp.username
            this.userId = resp._id
          }
                  console.log("this.organisationId ", this.organisationId)
          this.domainAndScreenPermissions.next(resp.domainAndScreenPermissions);
          this.userDetails.next(resp);
        }
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  organisationRegister(organisationData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'organisationRegister', organisationData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  updateSalesOrderSql(data) {
    // debugger
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'updateSalesOrderSql', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  organisationUpdate(organisationData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'organisationUpdate', organisationData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  pricedetailsUpdate(priceData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'addPricing', priceData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  finalPayments(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'pay', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  paymentdetailsUpdate(paymentData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'addPayment', paymentData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  billingdetailsUpdate(billingData) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'saveBillingDetails', billingData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'login', data).subscribe((resp: any) => {
        this.getUserDetails(resp._id)
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  otpVarification(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'verifyNumber', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  emailOtpVarification(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'verify', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  fetchOrg(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'fetchOrg', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  customeronboard(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'partnerRegister', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  customeronboardcm(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'createCustomerDummy', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  createArticlePart(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'createUnderArmour', data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  getMaterialNumber(){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'fetchUnderArmourMaterial').subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  getArticlePartner(){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'fetchUnderArmour').subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
  passedArticlePartner(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'createUnderArmour',data).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
}
